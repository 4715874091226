<template>
  <div class="relative flex flex-row w-28 h-10 mt-1 bg-transparent rounded-lg" v-if="product.producten_id">
    <button data-action="decrement" @click="changeCart(product.producten_id.id, (stapGrootte ?? 1) * -1)" :disabled="product.aantal < (stapGrootte ?? 1)"
            class="w-20 h-full text-white rounded-l outline-none cursor-pointer bg-primary-500 hover:bg-primary-300 disabled:cursor-not-allowed">
      <span class="m-auto text-2xl font-thin">−</span>
    </button>
    <input type="number" @change="setQuantity(product.producten_id.id, $event.target.value)"
           class="flex items-center w-full font-semibold text-center text-white outline-none focus:outline-none bg-primary-500 text-md md:text-basecursor-default"
           name="custom-input-number" :value="product.aantal"/>
    <!-- <button v-if="product.producten_id.aantal_op_voorraad" :disabled="product.aantal == product.producten_id.aantal_op_voorraad || product.aantal > product.producten_id.aantal_op_voorraad" @click="cartStore.changeCart(product.producten_id.id, 1)"
            class="w-20 h-full text-white rounded-r cursor-pointer bg-primary-500 hover:bg-primary-300 disabled:bg-gray-400 disabled:cursor-not-allowed" :value="product.aantal"> -->
    <button v-if="product.producten_id.aantal_op_voorraad" :disabled="product.aantal == product.producten_id.aantal_op_voorraad || product.aantal > product.producten_id.aantal_op_voorraad" @click="changeCart(product.producten_id.id, stapGrootte ?? 1)"
            class="w-20 h-full text-white rounded-r cursor-pointer bg-primary-500 hover:bg-primary-300 disabled:bg-gray-400 disabled:cursor-not-allowed" :value="product.aantal">
      <span class="m-auto text-2xl font-thin">+</span>
    </button>
    <button v-else :disabled="!product.producten_id.voorraad" @click="changeCart(product.producten_id.id, stapGrootte ?? 1)"
            class="w-20 h-full text-white rounded-r cursor-pointer bg-primary-500 hover:bg-primary-300 disabled:bg-gray-400 disabled:cursor-not-allowed" :value="product.aantal">
      <span class="m-auto text-2xl font-thin">+</span>
    </button>
  </div>

  <div class="relative flex flex-row w-28 h-10 mt-1 bg-transparent rounded-lg" v-else>
    <button data-action="decrement" @click="changeCart(product.producten_id.id, (stapGrootte ?? 1) * -1 )"
            class="w-20 h-full text-white rounded-l outline-none cursor-pointer bg-primary-500 hover:bg-primary-300">
      <span class="m-auto text-2xl font-thin">−</span>
    </button>
    <input type="number" @change="setQuantity(product.producten_id.id, $event.target.value)"
           class="flex items-center w-full font-semibold text-center text-white outline-none focus:outline-none bg-primary-500 text-md md:text-basecursor-default"
           name="custom-input-number" :value="product.aantal"/>
    <!-- <button v-if="product.aantal_op_voorraad" :disabled="product.aantal == product.aantal_op_voorraad || product.aantal > product.aantal_op_voorraad" @click="cartStore.changeCart(product.id, 1)"
      class="w-20 h-full text-white rounded-r cursor-pointer bg-primary-500 hover:bg-primary-300 disabled:bg-gray-400 disabled:cursor-not-allowed" :value="product.aantal"> -->
    <button v-if="product.aantal_op_voorraad" :disabled="product.aantal == product.aantal_op_voorraad || product.aantal > product.aantal_op_voorraad" @click="changeCart(product.id, stapGrootte ?? 1)"
      class="w-20 h-full text-white rounded-r cursor-pointer bg-primary-500 hover:bg-primary-300 disabled:bg-gray-400 disabled:cursor-not-allowed" :value="product.aantal">
      <span class="m-auto text-2xl font-thin">+</span>
    </button>
    <button v-else :disabled="!product.voorraad" @click="changeCart(product.id, stapGrootte ?? 1)"
            class="w-20 h-full text-white rounded-r cursor-pointer bg-primary-500 hover:bg-primary-300 disabled:bg-gray-400 disabled:cursor-not-allowed" :value="product.aantal">
      <span class="m-auto text-2xl font-thin">+</span>
    </button>
  </div>
</template>

<script setup>
const{$bus} = useNuxtApp()
const user = useDirectusUser();

import { useCart } from "~/store/useCart";
const cartStore = useCart();

const stapGrootte = ref(1);

const props = defineProps({
  product: {
    type: Object,
    default: () => ({}),
    required: false
  },
  max: {
    type: Number,
    required: false,
    default: 999999
  },
  voorraad: {
    type: Boolean,
    required: false,
    default: true
  }
});

onMounted(() => {
  if (props.product && props.product.producten_id) {

    if(props.product.producten_id.eenheid === 'kilo'){
      if(props.product.producten_id.kilo_stapgrootte){
        stapGrootte.value = props.product.producten_id.kilo_stapgrootte;
      }
    }
  }
});

function changeCart (id, amount) {
  cartStore.changeCartQuantity(id, amount);
  $bus.$emit('shoppingCardChanged');
}

function setQuantity (id, amount) {
  cartStore.setCartQuantity(id, parseFloat(amount));
  $bus.$emit('shoppingCardChanged');
}
</script>